import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";

import { BulkLoadInterface, ClientInterface, ClientProfileInterface } from "@clients/ports/interfaces";

export abstract class ClientAbstraction {
  abstract list(query: string): Observable<RespListInterface<ClientInterface>>;
  abstract create(client: ClientInterface): Observable<RespInterface<string>>;
  abstract update(client: ClientInterface): Observable<RespInterface<string>>;
  abstract read(id: string): Observable<RespInterface<ClientInterface>>;
  abstract delete(id: string): Observable<RespInterface<string>>;
  abstract profile(): Observable<RespInterface<ClientInterface>>;
  abstract profileUpdate(client: ClientProfileInterface): Observable<RespInterface<ClientInterface>>;
  
  abstract fileUpload(client: BulkLoadInterface): Observable<RespInterface<any>>;
}
